import '../styles/App.css';

function App() {
  return (
    <div className='App'>
      <iframe
        src='https://gamma.app/embed/y7ony32pmdhnnyi'
        className='fullscreen-iframe'
        title='kzheng'
      />
    </div>
  );
}

export default App;
